import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostItem from "../components/post/item"

export default ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <div>
        <h1>
          Articoli
        </h1>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <PostItem key={node.id} post={node}/>
        ))}
      </div>
    </Layout>
  )
}

/*
<h4>{data.allMarkdownRemark.totalCount} Posts</h4>
*/
export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY", locale: "it")
          }
          fields {
            slug
          }
          excerpt(pruneLength: 500)
        }
      }
    }
  }
`